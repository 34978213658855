// TEMP HACK UNTIL I FIGURE OUT HOW TO DO THIS RIGHT; use the localhost setting when running locally
// under npm start, the empty setting for everything else.
// const host = 'http://localhost:5000'
const host = ''

// Generic requests
export interface RequestParams {
  path: string;
  post?: boolean;
  data?: object;
};

export const request = <T,>(params: RequestParams): Promise<T> => {
  // In the future, if we have auth etc, we'll stuff the headers here.
  return fetch(
    `${host}${params.path}`,
    {
      method: params.post ? 'POST' : 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      body: params.data ? JSON.stringify(params.data) : null,
    }
  ).then((result) => result.json() as T);
};

export const newSession = (
  {deck, restrictions}: {deck: string, restrictions: DeckRestrictions}
): Promise<string> => {
  interface NewSessionResult {
    sessionId: string;
    error?: string;
  };
  return request<NewSessionResult>({
    path: '/api/sessions/new',
    post: true,
    data: { deck, restrictions }
  })
    .then((result) => {
      if (result.error !== undefined) {
        return Promise.reject(result.error);
      } else {
        return result.sessionId;
      }
    });
};

export interface DeckRestrictions {
  [index: string]: boolean | null;
};

export interface ShownCard {
  deck: string;
  card_id: string;
};

export interface SessionData {
  session_id: string;
  // The deck from which cards will be drawn
  deck: string | null;
  restrictions: DeckRestrictions;
  // The list of cards shown in this session, which we can page around in.
  shown: ShownCard[];
  expiration: string;
};

export const getSession = ({sessionId}: {sessionId: string}): Promise<SessionData> => {
  return request<SessionData>({path: `/api/session/${sessionId}`});
};

export type TextAlignment = "justify"|"center"|"right";

export interface TextLine {
  text: string;
  isTitle: boolean;
  alignment: TextAlignment;
};

export interface CardData {
  cardId: string;
  text: TextLine[];
  attributes: object;
};

// These interfaces are what comes over the wire
interface RawTextLine {
  text: string;
  is_title: boolean;
  alignment: number;
};

interface RawCardData {
  card_id: string;
  text: RawTextLine[];
  attributes: object;
};

const RAW_ALIGN_MAP: TextAlignment[] = ["justify", "center", "right"];

const normalizeCardData = (raw: RawCardData): CardData => (
  {
    cardId: raw.card_id,
    text: raw.text.map((line) => ({
      text: line.text,
      isTitle: line.is_title,
      alignment: RAW_ALIGN_MAP[line.alignment],
    })),
    attributes: raw.attributes,
  }
);

export const getCard = ({deck, cardId}: {deck: string, cardId: string}): Promise<CardData> => {
  return request<RawCardData>({
    path: `/api/deck/${deck}/card/${cardId}`,
  }).then(normalizeCardData);
};

export interface NextCardResult {
  session: SessionData;
  card: CardData;
};

interface RawNextCardResult {
  session: SessionData;
  card: RawCardData;
};

export const getNextCard = ({sessionId}: {sessionId: string}): Promise<NextCardResult> => {
  return request<RawNextCardResult>({path: `/api/session/${sessionId}/next`, post: true}).then(
    (result) => ({session: result.session, card: normalizeCardData(result.card)})
  );
};

export interface DeckAttributes {
  // Map from attribute to count of cards with that attribute
  [index: string]: number;
};

export interface DeckMetadata {
  name: string;
  size: number;
  attributes: DeckAttributes;
  can_reload: boolean;
};

export interface AllDecks {
  [index: string]: DeckMetadata;
};

export const getAllDecks = (): Promise<AllDecks> => {
  return request<AllDecks>({path: '/api/decks'});
};

export const reloadDeck = (deck: string): Promise<DeckMetadata> => {
  return request<DeckMetadata>({path: `/api/deck/${deck}/reload`, post: true});
}

export const reloadAll = (): Promise<AllDecks> => {
  return request<AllDecks>({path: '/api/decks/reload', post: true});
}

export interface UpdatePrefsParams {
  sessionId: string;
  deck: string;
  restrictions: DeckRestrictions;
};

export const updatePrefs = (params: UpdatePrefsParams): Promise<SessionData> => {
  // Translate from the JS-friendly format to one the BE wants
  interface WireRestrictions {
    [index: string]: boolean;
  };
  const restrictions: WireRestrictions  = {};
  for (const [key, value] of Object.entries(params.restrictions)) {
    if (value !== null) {
      restrictions[key] = value;
    }
  }

  return request<SessionData>({
    path: `/api/session/${params.sessionId}/prefs`,
    post: true,
    data: {
      deck: params.deck,
      restrictions,
    },
  });
};
