import './Homepage.css';
import GamePreferences from './GamePreferences';
import SessionPreferences from './SessionPreferences';
import { newSession, DeckRestrictions } from './api';

import { useState } from 'react';

import { useNavigate } from 'react-router-dom';

function Homepage() {
  const navigate = useNavigate();

  const [ showGamePrefs, setShowGamePrefs ] = useState(false);
  const [ showSessionPrefs, setShowSessionPrefs ] = useState(false);

  const onStartGame = () => { setShowSessionPrefs(true); }
  const onSessionPrefsCancel = () => { setShowSessionPrefs(false); }
  const onSessionPrefsSave = (deck: string, restrictions: DeckRestrictions) => {
    newSession({deck, restrictions}).then((value) => { navigate(`/${value}`); })
  }

  return (
    <div className="Homepage">
      <header className="Homepage-header">
        <GamePreferences showPrefs={showGamePrefs} setShowPrefs={setShowGamePrefs} />
        <SessionPreferences
         showPrefs={showSessionPrefs}
         onCancel={onSessionPrefsCancel}
         onSave={onSessionPrefsSave}
         saveLabel="Start"
         initialDeck={null}
         initialRestrictions={null}
        />
        <span className="Homepage-prefs-link" onClick={()=>{setShowGamePrefs(true)}} >
          &#x2699;
        </span>
        <div className="Homepage-title">
            Cards
        </div>
        <div className="Homepage-link" onClick={onStartGame}>
            Start a game
        </div>
      </header>
    </div>
  );
}

export default Homepage;
