import './Session.css';

import { useEffect, useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';

import { useWindowSize } from '@react-hook/window-size';

import Box from '@mui/material/Box';
import HistoryIcon from '@mui/icons-material/History';
import Tooltip from '@mui/material/Tooltip';

import Card, { gotCardInfo } from './Card';
import SessionPreferences from './SessionPreferences';
import { SessionData, getSession, getNextCard, DeckRestrictions, updatePrefs } from './api';
import { componentDimensions, px } from './dimensions';


////////////////////////////////////////////////////////////////////////////////////////////////
// The InnerSession object is the real meat of the UI; it implements a session (aka a game) of
// Cards.

// TODO Reduce this to a smaller number for launch; this is the interval delay for a change of state
// to reach other players.
const REFRESH_INTERVAL_MS = 5000;

enum Status {
  Loading = 1,
  Loaded,
  Failed,
};

const InnerSession = ({sessionId}: {sessionId: string}) => {
  // The viewport properties. (Grab first so we can propagate these downstream)
  const [width, height] = useWindowSize({initialWidth: 1024, initialHeight: 768});
  const dimensions = componentDimensions(width, height);

  // The session state.
  const [ status, setStatus ] = useState(Status.Loading);
  const [ sessionState, setSessionState ] = useState<SessionData>(
    {
      session_id: sessionId,
      deck: null,
      restrictions: {},
      shown: [],
      expiration: "",
    }
  );
  // This is true when a next-card fetch is in progress, to avoid some race conditions in useEffect.
  const [ isFetching, setIsFetching ] = useState(false);

  // The active card. cardIndex is an array index into sessionState.shown.
  const [ cardIndex, setCardIndex ] = useState<number>(0);
  // If this is true, we're viewing the "latest" card and should update that if the session changes.
  const [ isViewingLatest, setIsViewingLatest ] = useState(true);
  const card = (
    status === Status.Loaded && cardIndex >= 0 && cardIndex < sessionState.shown.length ?
    <Card
      deck={sessionState.shown[cardIndex].deck}
      cardId={sessionState.shown[cardIndex].card_id}
      width={dimensions.interfaceWidth}
      height={dimensions.cardHeight}
    /> :
    null
  );

  useEffect(() => {
    const refreshSessionState = () => {
      getSession({sessionId})
        .then(
          (result) => {
            setStatus(Status.Loaded);
            setSessionState(result);
            if (isViewingLatest && result.shown.length > 0) {
              setCardIndex(result.shown.length - 1);
            }
          },
          (error) => {
            if (status === Status.Loading) { setStatus(Status.Failed); }
          },
         );
    };

    refreshSessionState();
    const interval = setInterval(refreshSessionState, REFRESH_INTERVAL_MS);
    return () => { clearInterval(interval); }
  }, [sessionId, status, isViewingLatest]);

  // The preferences pane. If we load a session and there's no deck selected, immediately open it.
  const [ showPrefs, setShowPrefs ] = useState(false);
  const onPrefsCancel = () => { setShowPrefs(false); }
  const onPrefsSave = (deck: string, restrictions: DeckRestrictions) => {
    updatePrefs({sessionId, deck, restrictions}).then(
      (session) => {
        setSessionState(session);
        setShowPrefs(false);
      }
    );
  }

  // Underlying operation for "load the next card."
  const loadNext = () => {
    if (isFetching) return;
    setIsFetching(true);
    getNextCard({sessionId})
      .then(
        (result) => {
          setSessionState(result.session);
          gotCardInfo({
            deck: sessionState.deck!,
            cardId: result.card.cardId,
            card: result.card
          });
          setCardIndex(result.session.shown.length - 1);
          setIsFetching(false);
        },
      );
  }

  // Helper functions for our buttons
  const firstCard = () => {
    setCardIndex(0);
    setIsViewingLatest(false);
  }
  const lastCard = () => {
    if (sessionState.shown != null) {
      setCardIndex(sessionState.shown.length - 1);
    }
    setIsViewingLatest(true);
  }
  const prevCard = () => {
    if (cardIndex > 0) {
      setCardIndex(cardIndex - 1);
    }
    setIsViewingLatest(false);
  }
  const nextCard = () => {
    if (sessionState.shown == null || sessionState.deck == null) {
      // No cards; do nothing.
      return;
    } else if (cardIndex < sessionState.shown.length - 1) {
      // We're not at the end of the deck.
      setCardIndex(cardIndex + 1);
    } else {
      // We're at the end of the deck! Flip a card.
      loadNext();
    }
  }

  // If there's a deck selected and there's no card, immediately get a next card.
  useEffect(() => {
    if (sessionState.deck !== null && sessionState.shown.length === 0) {
      loadNext();
    }
  }, [sessionState]);

  const firstCardButton = (
    status === Status.Loaded && sessionState.shown.length > 0 ?
    <span className="Session-control" onClick={firstCard}>&#x21e4;</span> :
    <span className="Session-control-disabled">&#x21e4;</span>
  );
  const lastCardButton = (
    status === Status.Loaded && (!isViewingLatest || cardIndex < sessionState.shown.length - 1) ?
    <span className="Session-control" onClick={lastCard}>&#x21e5;</span> :
    <span className="Session-control-disabled">&#x21e5;</span>
  );
  const prevCardButton = (
    status === Status.Loaded && cardIndex > 0 ?
    <span className="Session-control" onClick={prevCard}>&#x2190;</span> :
    <span className="Session-control-disabled">&#x2190;</span>
  );
  const nextCardButton = (
    status === Status.Loaded ?
    <span className="Session-control" onClick={nextCard}>&#x2192;</span> :
    <span className="Session-control-disabled">&#x2192;</span>
  );

  const showHistoryIndicator = status === Status.Loaded && !isViewingLatest;
  const historyIndicatorSize = 0.8 * dimensions.controlFontSize;
  const innerHistoryIndicator = (
    <HistoryIcon
     className={
       showHistoryIndicator ?  "Session-history-indicator" : "Session-history-indicator-hidden"
     }
     sx={{ height: historyIndicatorSize, width: historyIndicatorSize }}
    />
  );
  const historyIndicator = showHistoryIndicator ? (
    <Tooltip
     title="You are scrolling through the deck history. To see what other people are seeing, jump to the last card using the button farthest to the right."
    >
      {innerHistoryIndicator}
    </Tooltip>
  ) : innerHistoryIndicator;


  const buttons = (
    <Box
      className="Session-control-box"
      width={px(dimensions.interfaceWidth)} 
      fontSize={px(dimensions.controlFontSize)}
    >
      {firstCardButton}
      {prevCardButton}
      {historyIndicator}
      {nextCardButton}
      {lastCardButton}
    </Box>
  );

  const prefsButton = (
    status === Status.Loaded ?
    <span className="Session-prefs-link" onClick={() => setShowPrefs(true)}>&#x2699;</span> :
    null
  );
  const prefs = (
    <SessionPreferences
      showPrefs={showPrefs}
      onCancel={onPrefsCancel}
      onSave={onPrefsSave}
      saveLabel="Save"
      initialDeck={sessionState?.deck}
      initialRestrictions={sessionState?.restrictions}
    />
  );

  // TODO insert loading message
  return (
    <div className="Session">
      {prefs}
      {prefsButton}
      {card}
      {buttons}
    </div>
  )
}

////////////////////////////////////////////////////////////////////////////////////////////////
// Outer wrapper around the session.

type SessionParams = {
  sessionId: string;
};

const Session = () => {
  // The session ID.
  const { sessionId } = useParams<SessionParams>();
  if (sessionId === undefined) {
    return <Navigate to="/" />
  } else {
    return <InnerSession sessionId={sessionId} />
  }
}

export default Session;
