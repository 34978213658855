import './Card.css';
import { ReactComponent as RawScrollwork } from './scrollwork.svg';

import Box from '@mui/material/Box';

import { useEffect, useState } from 'react';
import LRUCache from 'lru-cache';
import { CardData, getCard, TextAlignment } from './api';
import { px } from './dimensions';

const cardCache = new LRUCache({max: 500});

export const gotCardInfo = ({deck, cardId, card}: {
  deck: string,
  cardId: string,
  card: CardData
}) => {
  cardCache.set({deck, cardId}, card);
};

// Fetch card data
const getCardData = ({deck, cardId}: {deck: string, cardId: string}): Promise<CardData> => {
  const cacheValue = cardCache.get({deck, cardId}) as CardData;
  if (cacheValue !== undefined) {
    return Promise.resolve(cacheValue);
  }
  return getCard({deck, cardId})
    .then(
      (result) => {
        gotCardInfo({deck, cardId, card: result});
        return result;
      }
    );
};

const Scrollwork = ({cardWidth, top}: {cardWidth: number, top: boolean}) => {
  const scrollworkWidth = 0.95 * cardWidth;
  const scrollworkHeight = (175 / 915) * scrollworkWidth;

  return (
    <Box
      className="svg-in-text-color"
      mt={top ? "15px" : ""}
      mb={top ? "" : "15px"}
    >
      <RawScrollwork
        width={px(scrollworkWidth)}
        height={px(scrollworkHeight)}
        transform={top ? "scale(1, -1)" : "scale(1, 1)"}
      />
    </Box>
  );
}

interface CardProps {
  deck: string;
  cardId: string;
  width: number;
  height: number;
};

const BOX_ALIGN = {
  justify: "flex-start",
  center: "center",
  right: "flex-end",
};

const textBoxes = (data: CardData): JSX.Element[] => {
  let lastAlignment: TextAlignment | null = null;

  return data.text.map((line, index) => {
    const pad = (lastAlignment !== line.alignment);
    lastAlignment = line.alignment;

    return (
      <Box
       key={`line-${index}`}
       className={line.isTitle ? "Card-title" : "Card-text"}
       alignSelf={BOX_ALIGN[line.alignment]}
       textAlign={line.alignment}
       mt={pad ? "0.5em" : ""}
      >
        {line.text}
      </Box>
    );
  });
};

const Card = (props: CardProps) => {
  const [ data, setData ] = useState<CardData | null>(null);

  useEffect(() => {
    getCardData({deck: props.deck, cardId: props.cardId}).then(setData);
  }, [props.deck, props.cardId]);

  return (
    <Box
      className='Card'
      width={px(props.width)}
      height={px(props.height)}
      borderRadius={10}
    >
      <Scrollwork cardWidth={props.width} top={true}/>
      <Box
       className="Card-textbox"
       width={px(props.width * 0.8)}
      >
        { data ? textBoxes(data) : null }
      </Box>
      <Scrollwork cardWidth={props.width} top={false}/>
    </Box>
  );
};

export default Card;
