import { useEffect, useState } from 'react';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import LoadingButton from '@mui/lab/LoadingButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { getAllDecks, AllDecks, reloadDeck, reloadAll } from './api';


interface GamePrefsParams {
  showPrefs: boolean;
  setShowPrefs: (value: boolean) => void;
};

const GamePreferences = (params: GamePrefsParams) => {
  const [ decks, setDecks ] = useState<AllDecks|null>(null);
  useEffect(() => {
    getAllDecks().then(setDecks);
  }, []);

  // Button and action handlers
  const [ isLoadingOne, setIsLoadingOne ] = useState(false);
  const [ isLoadingAll, setIsLoadingAll ] = useState(false);

  const onClose = () => {
    params.setShowPrefs(false);
  };

  const onReload = (deck: string) => {
    setIsLoadingOne(true);
    reloadDeck(deck).then(
      (metadata) => {
        const newDecks: AllDecks = {};
        if (decks !== null) {
          Object.assign(newDecks, decks);
        }
        newDecks[deck] = metadata;
        setDecks(newDecks);
        setIsLoadingOne(false);
      }
    );
  }

  const onReloadAll = () => {
    setIsLoadingAll(true);
    reloadAll().then((result) => {
      setDecks(result);
      setIsLoadingAll(false);
    });
  }

  return (
    <Dialog open={params.showPrefs} onClose={onClose} maxWidth="md">
      <DialogTitle>
        <Grid container>
          <Grid item xs={10}>
            Deck Management
          </Grid>
          <Grid item xs={2}>
            <LoadingButton
              loading={isLoadingAll}
              variant="contained"
              size="small"
              onClick={onReloadAll}
            >
              Reload All
            </LoadingButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent dividers>
        <FormControl fullWidth>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Deck</TableCell>
                <TableCell>Cards</TableCell>
                <TableCell>Options</TableCell>
                <TableCell>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            {
              decks ? Object.entries(decks).map(([deck, metadata]) => (
                <TableRow key={deck}>
                  <TableCell>{metadata.name}</TableCell>
                  <TableCell>{metadata.size.toLocaleString()}</TableCell>
                  <TableCell>
                    {
                      Object.entries(metadata.attributes).map(([attr, count]) => (
                        <p key={attr}>{attr} ({count.toLocaleString()} cards)</p>
                      ))
                    }
                  </TableCell>
                  <TableCell>
                    { metadata.can_reload ?
                      <LoadingButton
                       loading={isLoadingOne}
                       variant="contained"
                       size="small"
                       onClick={() => onReload(deck)}
                      >
                        Reload
                      </LoadingButton> :
                      null
                    }
                  </TableCell>
                </TableRow>
              )) : null
            }
            </TableBody>
          </Table>
        </FormControl>
      </DialogContent>
    </Dialog>
  );
};

export default GamePreferences;
