import { useEffect, useState } from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { AllDecks, getAllDecks, DeckRestrictions } from './api';

// TODO Change 'mandatory' to enable the cancel button, but have it delete the session and navigate
// back to home instead.

// We use this to label restrictions internally because MUI components don't like using null.
type NamedRestriction = "true"|"false"|"any";
interface NamedRestrictions { [index: string]: NamedRestriction; };

const deckToNamedRestrictions = (dr: DeckRestrictions): NamedRestrictions => {
  const result: NamedRestrictions = {}
  for (const [attr, value] of Object.entries(dr)) {
    if (value === null) {
      result[attr] = "any";
    } else if (value === true) {
      result[attr] = "true";
    } else {
      result[attr] = "false";
    }
  }
  return result;
};

const namedToDeckRestrictions = (nr: NamedRestrictions): DeckRestrictions => {
  const result: DeckRestrictions = {}
  for (const [attr, value] of Object.entries(nr)) {
    if (value === "any") {
      result[attr] = null;
    } else if (value === "true") {
      result[attr] = true;
    } else {
      result[attr] = false;
    }
  }
  return result;
};


interface SessionPrefsParams {
  // The boolean show/hide flag
  showPrefs: boolean;
  // What to do when the cancel button is hit
  onCancel: () => void;
  // What to do when the save button is hit
  onSave: (deck: string, restrictions: DeckRestrictions) => void;
  // The label of the save button
  saveLabel: string;
  // Initial values for deck and restriction selection
  initialDeck: string | null;
  initialRestrictions: DeckRestrictions | null;
};

const SessionPreferences = (params: SessionPrefsParams) => {
  const [ decks, setDecks ] = useState<AllDecks|null>(null);
  useEffect(() => {
    getAllDecks().then(setDecks);
  }, []);

  const [ deck, setDeck ] = useState<string | null>(params.initialDeck);
  const [ restrictions, setRestrictions ] = useState<NamedRestrictions | null>(
    params.initialRestrictions ? deckToNamedRestrictions(params.initialRestrictions) : null
  );

  const deckSelected = (newDeck: string) => {
    if (newDeck === deck) {
      return;
    }
    if (decks === null || !(newDeck in decks)) {
      throw Error(`Never happens: Selected unknown deck ${newDeck}`);
    }

    setDeck(newDeck);
    const newRestrictions: NamedRestrictions = {};
    Object.keys(decks[newDeck].attributes).forEach((attribute: string) => {
      newRestrictions[attribute] = "any";
    });
    setRestrictions(newRestrictions);
  };

  // Button and action handlers
  const onCloseButton = params.onCancel;
  const onSaveButton = () => {
    params.onSave(deck!, namedToDeckRestrictions(restrictions!));
  }
  const onDeckSelected = (event: SelectChangeEvent) => {
    deckSelected(event.target.value as string);
  }
  const onRestrictionSelected = (attr: string, value: NamedRestriction) => {
    const newRestrictions: NamedRestrictions = {};
    Object.assign(newRestrictions, restrictions);
    newRestrictions[attr] = value;
    setRestrictions(newRestrictions);
  }

  return (
    <Dialog open={params.showPrefs} maxWidth="md">
      <DialogTitle>Pick your deck!</DialogTitle>
      <DialogContent dividers>
        <FormControl fullWidth>
          <InputLabel id="deck-select-label">Deck</InputLabel>
          <Select
            labelId="deck-select-label"
            id="deck-select"
            value={deck || ""}
            label="Deck"
            onChange={onDeckSelected}
          >
            {
              decks ? Object.keys(decks).map((key) => (
                <MenuItem key={key} value={key}>
                  {key} ({decks[key].size.toLocaleString()} cards)
                </MenuItem>
              )) : null
            }
          </Select>
        </FormControl>
        <FormControl fullWidth>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell sx={{ minWidth: 100 }}>Card Attribute</TableCell>
                <TableCell>Options</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            {
              restrictions ?
              Object.keys(restrictions).map((attr) => (
                <TableRow key={attr}>
                  <TableCell>
                    {attr} ({decks![deck!].attributes[attr].toLocaleString()} cards)
                  </TableCell>
                  <TableCell>
                    <RadioGroup
                      row
                      value={restrictions[attr]}
                      onChange={(event) => {
                        onRestrictionSelected(attr, event.target.value as NamedRestriction)}
                      }
                    >
                      <FormControlLabel value="true" control={<Radio/>} label="Only these" />
                      <FormControlLabel value="false" control={<Radio/>} label="Never these" />
                      <FormControlLabel value="any" control={<Radio/>} label="Either way" />
                    </RadioGroup>
                  </TableCell>
                </TableRow>
              )) : null
            }
            </TableBody>
          </Table>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCloseButton}>Cancel</Button>
        <Button onClick={onSaveButton} disabled={deck === null}>{params.saveLabel}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default SessionPreferences;
