export interface ComponentDimensions {
  // The width of the "interface components" within the page. Serves as width for the button array
  // and the card alike.
  interfaceWidth: number;

  // The control item font size, in pixels, and the gap width.
  controlFontSize: number;
  controlGapWidth: number;

  // The card height (in pixels)
  cardHeight: number;
};

const MAX_CONTROL_WIDTH = 550;
const MIN_CONTROL_WIDTH = 150;
const MAX_CONTROL_FONT_SIZE = 96;
const MAX_CONTROL_GAP_WIDTH = 30;
const CARD_ASPECT_RATIO = 1.4;  // Standard playing cards

export const componentDimensions = (width: number, height: number): ComponentDimensions => {
  // Our default approach to picking a width is by scaling based on the hsize of the page.
  let interfaceWidth = Math.max(Math.min(width, MAX_CONTROL_WIDTH), MIN_CONTROL_WIDTH);

  // The resulting height of the whole interface:
  const resultingHeight = (
    (interfaceWidth * CARD_ASPECT_RATIO) +  // Card
    (MAX_CONTROL_FONT_SIZE * interfaceWidth / MAX_CONTROL_WIDTH) +  // Control box
    50  // Column gap
  );

  // If that's too big, reduce the interface width until the whole control panel fits on a page.
  if (resultingHeight > height) {
    interfaceWidth = interfaceWidth * (height / resultingHeight);
  }

  const hScale = interfaceWidth / MAX_CONTROL_WIDTH;

  return {
    interfaceWidth,
    controlFontSize: MAX_CONTROL_FONT_SIZE * hScale,
    controlGapWidth: MAX_CONTROL_GAP_WIDTH * hScale,
    cardHeight: interfaceWidth * CARD_ASPECT_RATIO,
  };
};

export const px = (size: number): string => (`${size}px`);
